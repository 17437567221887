var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4"}},[_c('v-alert',{staticClass:"small-text mb-0",attrs:{"color":"info","icon":"info","outlined":""}},[_vm._v(" Esta interfaz le permite especificar los proveedores del producto actual y eventualmente de sus combinaciones. ")]),_c('supplier-form',{on:{"save-success":_vm.getpage},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8","lg":"8"}},[_c('v-widget',{staticClass:"form-widget",attrs:{"title":"Codigos de Productos por Proveedor","simpleToolbar":"","icon":"list","loading":_vm.loading}},[_c('div',{attrs:{"slot":"widget-header-action"},slot:"widget-header-action"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-2",attrs:{"small":"","icon":"","loading":_vm.loading},on:{"click":_vm.getpage}},on),[_c('v-icon',[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.L('Actualizar')))])])],1),_c('div',{attrs:{"slot":"widget-content"},slot:"widget-content"},[_c('v-data-table',{staticClass:"elevation-0 striped products-details",attrs:{"headers":_vm.columns,"items":_vm.list,"item-key":"id","group-by":"supplier_name","loading":_vm.loading,"loading-text":"Cargando... Por favor Espere","disable-pagination":_vm.totalCount < 11,"hide-default-footer":_vm.totalCount < 11,"dense":"","footer-props":{
                              itemsPerPageOptions: [10,20,50,-1],
                              itemsPerPageText: 'Registros por Pagina',
                              showFirstLastPage: true,
                            }},scopedSlots:_vm._u([(_vm.loading)?{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-row-divider@5"}})]},proxy:true}:null,{key:"group.header",fn:function(ref){
                            var items = ref.items;
                            var isOpen = ref.isOpen;
                            var toggle = ref.toggle;
return [_c('td',{staticStyle:{"background-color":"#E0F7FA"},attrs:{"colspan":"3"}},[_c('h5',{staticClass:"font-weight-bold"},[_c('v-btn',{staticClass:"mr-4",attrs:{"icon":"","small":"","color":"red","loading":_vm.loading},on:{"click":function($event){return _vm.deleteSupplier(items[0])}}},[_c('v-icon',[_vm._v("delete")])],1),_vm._v(" "+_vm._s(items[0].supplier_name)+" ")],1)])]}},{key:"item.supplier_reference",fn:function(ref){
                            var item = ref.item;
return [_c('v-text-field',{staticClass:"detail-quantity",staticStyle:{"max-width":"150px"},attrs:{"prefix":"#","type":"text","dense":"","hide-details":""},on:{"change":function($event){return _vm.update(item)}},model:{value:(item.supplier_reference),callback:function ($$v) {_vm.$set(item, "supplier_reference", $$v)},expression:"item.supplier_reference"}})]}},{key:"item.supplier_cost",fn:function(ref){
                            var item = ref.item;
return [_c('v-text-field',{staticClass:"detail-quantity",staticStyle:{"max-width":"150px"},attrs:{"prefix":_vm.product.currency_sign,"type":"number","dense":"","hide-details":""},on:{"change":function($event){return _vm.update(item)}},model:{value:(item.supplier_cost),callback:function ($$v) {_vm.$set(item, "supplier_cost", $$v)},expression:"item.supplier_cost"}})]}},{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"d-flex flex-wrap text-center justify-space-around",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-icon',{attrs:{"size":"100px","color":"success"}},[_vm._v("add_box")])],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('h4',[_vm._v("Agrege algun proveedor!")])]),_c('v-col',{staticStyle:{"font-size":"16px"},attrs:{"cols":"12","lg":"12"}})],1)]},proxy:true}],null,true)})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }