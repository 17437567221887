var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal-view',{ref:"modal",attrs:{"mainSetting":_vm.modalSetting},on:{"on-cancel":_vm.cancel},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('tempalte',{attrs:{"slot":"modal-header-action"},slot:"modal-header-action"},[_c('v-btn-toggle',{attrs:{"tile":"","group":"","mandatory":""},model:{value:(_vm.vType),callback:function ($$v) {_vm.vType=$$v},expression:"vType"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"icon":"","value":"list"}},on),[_c('v-icon',[_vm._v("list")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.L('Ver Lista')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"icon":"","value":"block"}},on),[_c('v-icon',[_vm._v("widgets")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.L('Ver cuadros')))])])],1)],1),_c('div',{attrs:{"slot":"modal-content-side1"},slot:"modal-content-side1"},[(_vm.loading)?_c('v-row',{staticClass:"text-center"},_vm._l((8),function(n){return _c('v-col',{key:n,staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"3","lg":"3"}},[_c('v-skeleton-loader',{attrs:{"type":"card"}})],1)}),1):_c('div',[(_vm.vType == 'block' && _vm.list != null && _vm.list.length > 0)?_c('v-row',{staticClass:"text-center",attrs:{"dense":""}},_vm._l((_vm.list),function(pro,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"3","lg":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":"","elevation":hover ? 4 : 0}},[_c('v-card-title',{staticClass:"subtitle-2 mt-2 pb-0 text-left"},[_c('v-chip',[_vm._v(_vm._s(pro.product_related.code))]),_c('v-spacer'),_c('v-chip',[_vm._v("Stock: "+_vm._s(pro.product_related.stock))])],1),(pro.product_related != null && pro.product_related.images.length > 0)?_c('v-img',{staticClass:"mt-1",attrs:{"height":"130px","src":pro.product_related.images[0].url_box != null ? pro.product_related.images[0].url_box : pro.product_related.images[0].url}}):_c('v-img',{staticClass:"mt-1",attrs:{"height":"130px","src":"static/product.jpg"}}),_c('v-card-text',{staticClass:"pa-2"},[_c('span',{staticClass:"primary--text caption"},[_vm._v(" "+_vm._s(pro.product_related.name)+" ")])])],1)]}}],null,true)})],1)}),1):(_vm.vType == 'list' && _vm.list != null && _vm.list.length > 0)?_c('v-list-item-group',{attrs:{"active-class":"success--text"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._l((_vm.list),function(pro,index){return [_c('v-list-item',{key:pro.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-avatar',{attrs:{"size":"30"}},[_c('v-avatar',{attrs:{"color":"primary","size":"30"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.getInitials(pro.product_related.name)))])])],1),_c('v-list-item-content',{staticStyle:{"min-width":"70%"}},[_c('v-list-item-subtitle',{staticClass:"body-2"},[_vm._v(_vm._s(pro.product_related.code)+" - "+_vm._s(pro.product_related.name))])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Precio: "),_c('b',[_vm._v(_vm._s(_vm.currency_sign)+" "+_vm._s(pro.product_related.price_wt.toFixed(2)))])])],1),_c('v-list-item-action',[_c('v-list-item-subtitle',[_vm._v("Stock: "),_c('b',[_vm._v(_vm._s(pro.product_related.stock))])])],1)]}}],null,true)}),_c('v-divider')]})],2):_c('v-row',{staticClass:"d-flex flex-wrap text-center justify-space-around my-12 py-12",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-icon',{attrs:{"size":"100px","color":"#F8BB86"}},[_vm._v("info_outline")])],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('h4',[_vm._v("Aun no hay productos relacionados!")])]),_c('v-col',{staticStyle:{"font-size":"16px"},attrs:{"cols":"12","lg":"12"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }